import media from "utils/mediaqueries";
import { styled } from "utils/emotion";
import Link from "components/Link";

export const BannerTextContainerStyled = styled("div")(
  {
    pointerEvents: "all",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "auto",
    // ----------------------------
    // controls the height of banner type 1
    padding: "23px 50px",
    // ----------------------------
    // height: '100%',
  },
  (props) => ({
    a: {
      color: props.layout === "green" ? "aqua" : props.theme.colorTertiary,
    },
    boxShadow:
      props.layout === "green" ? "none" : "0px 0px 10px rgba(0,0,0,0.3)",
    borderTop:
      props.position === "bottom"
        ? 0
        : props.layout === "green"
        ? "0"
        : `10px solid ${props.theme.borderColorTertiary}`,
    borderBottom:
      props.position === "top"
        ? 0
        : props.layout === "green"
        ? "0"
        : `10px solid ${props.theme.borderColorTertiary}`,
    width: props.stretch ? "100%" : "auto",
    maxWidth: props.stretch ? "100%" : 570,
    background:
      props.layout === "green"
        ? props.theme.popupOfferContentBackground
        : "white",
  })
);

export const PopupGridStyled = styled("div")(
  {
    justifyContent: "center",

    [media(0, "tablet")]: {
      flexWrap: "wrap",
    },

    [media("tablet")]: {
      display: "flex",
    },
  },
  (props) => ({
    gridTemplateColumns: props.gridTemplateColumns,
  })
);

export const BannerCloseWrapperStyled = styled("div")({
  // height: '100%',
  minWidth: 110,
  minHeight: 60,
  pointerEvents: "all",
  display: "flex",

  [media(0, "tablet")]: {
    width: "100%",
  },

  [media(0, "mobile")]: {
    // height: '50px',
  },
});

export const BannerContainerStyled = styled("div")(
  {
    pointerEvents: "none",
    position: "fixed",
    left: 0,
    width: "100%",
    margin: "0 auto",
    zIndex: 2147483647,
    transition: "transform 150ms ease-out",

    // [media(0, 'tablet')]: {
    //   display: 'none'
    // },
  },
  (props) => ({
    padding: props.layout === "green" ? 15 : 0,
    top: props.position === "top" ? 0 : "auto",
    bottom: props.position === "bottom" ? 0 : "auto",
    transform:
      props.show === false
        ? `translateY(${props.position === "top" ? "-100%" : "100%"})`
        : "translateY(0%)",
    opacity: props.show === false ? 0 : 1,
  })
);

export const LinkStyled = styled(Link)(
  {
    zIndex: 15,
    ":hover": {
      textDecoration: "underline",
    },
  },
  (props) => ({
    // color: props.theme.colorTertiary,
  })
);

export const ParagraphStyled = styled("p")({
  fontSize: "1rem",
  color: "black",
  textAlign: "center",
  fontWeight: 600,
  letterSpacing: "1px",
});

export const BannerCountdownWrapper = styled("div")(
  {
    pointerEvents: "all",
    // ------------------
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    position: "relative",
    // ------------------
    // controls height of banner type 2
    padding: "10px",
    minWidth: 170,

    [media(0, "tablet")]: {
      width: "100%",
      justifyContent: "center",
    },
  },
  (props) => ({
    backgroundColor:
      props.layout === "green" ? "rgb(2,119,116)" : props.theme.colorTertiary,
  })
);

export const BannerCountdownUnit = styled("div")({
  fontWeight: 400,
  fontSize: ".6rem",
});

export const BannerCountdownTimeWrapper = styled("div")({
  textAlign: "center",
  fontWeight: 600,
  fontSize: "1.2rem",
  padding: "5px",
  [media(0, 300)]: {
    fontSize: "1rem",
    padding: "0px",
  },
});

export const BannerCountdownDots = styled("div")({
  top: "7px",
  position: "relative",
});

export const CountdownTextStyled = styled("span")({
  fontSize: 13,
  fontWeight: 500,
  opacity: 0.7,
});
