import React from 'react';

import { graphql } from 'gatsby';
import PageBanner from '../PageBanner';

export default function GlobalBanner({
  currentPage,
  bannerSiteSelection,
  offerPopupExpirationDate,
  offerPopupHeadline,
  offerPopupType,
  offerPopupLayout,
  offerPopupStretch,
  offerPopupPosition,
  offerPopupLink,
  onHide,
  pageContext,
  visible,
}) {
  const pages = bannerSiteSelection.map(({ title }) => title); // flat array
  const visibleOnAllPages = pages.length === 0;
  const visibleOnCurrentPage = pages.includes(currentPage);
  const popupLink = offerPopupLink?.entry?.fullUri || offerPopupLink?.url || '';
  const headlineContent = offerPopupHeadline ? offerPopupHeadline.content : '';

  return visibleOnCurrentPage || visibleOnAllPages ? (
    <PageBanner
      visible={visible}
      onHide={onHide}
      fullUri={popupLink}
      headlineHtml={headlineContent}
      offerPopupExpirationDate={offerPopupExpirationDate}
      offerPopupType={offerPopupType}
      offerPopupLayout={offerPopupLayout}
      offerPopupStretch={offerPopupStretch}
      offerPopupPosition={offerPopupPosition}
      pageContext={pageContext}
    />
  ) : null;
}

export const query = graphql`
  fragment GlobalBannerQuery on Craft_GlobalsSet {
    banner {
      offerPopupHeadline {
        content
      }
      offerPopupLink {
        url
        entry {
          fullUri
        }
      }
      offerPopupType
      offerPopupLayout
      offerPopupStretch
      offerPopupPosition
      offerPopupExpirationDate
      bannerSiteSelection {
        title
        fullUri
        id
      }
    }
  }
`;
