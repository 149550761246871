import React, { useRef } from 'react';
import CountdownNow from 'react-countdown-now';

import {
  BannerCountdownWrapper,
  BannerCountdownUnit, 
  BannerCountdownTimeWrapper, 
  BannerCountdownDots
} from '../styles';

const BannerCountdown = ({countdownDateTime, layout}) => {
  
  const countdown = useRef();
  const date = new Date(Number(countdownDateTime) * 1e3);

  return (
    <BannerCountdownWrapper layout={layout}>
      <CountdownNow
        date={date}
        ref={countdown}
        renderer={({ days, hours, minutes, seconds }) => (
          <div
            style={{
              display: 'inline-flex',
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}
          >
            <BannerCountdownTimeWrapper>
              <div>{String(days).padStart(2, '0')}</div>
              <BannerCountdownUnit>TAGE</BannerCountdownUnit>
            </BannerCountdownTimeWrapper>
      
            <BannerCountdownDots>:</BannerCountdownDots>
            <BannerCountdownTimeWrapper>
              <div>{String(hours).padStart(2, '0')}</div>
              <BannerCountdownUnit>STD</BannerCountdownUnit>
            </BannerCountdownTimeWrapper>
      
            <BannerCountdownDots>:</BannerCountdownDots>      
            <BannerCountdownTimeWrapper>
              <div>{String(minutes).padStart(2, '0')}</div>
              <BannerCountdownUnit>MIN</BannerCountdownUnit>
            </BannerCountdownTimeWrapper>

            <BannerCountdownDots>:</BannerCountdownDots>
            <BannerCountdownTimeWrapper>
              <div>{String(seconds).padStart(2, '0')}</div>
              <BannerCountdownUnit>SEK</BannerCountdownUnit>
            </BannerCountdownTimeWrapper>
          </div>
        )}
      />
    </BannerCountdownWrapper>
  );
};
export default BannerCountdown;
