import React from 'react';
import { styled } from 'utils/emotion';

const Button = styled('button')({
  backgroundColor: 'black',
  color: 'white',
  border: 'none',
  fontSize: '.7rem',
  letterSpacing: '1px',
  fontWeight: 600,
  cursor: 'pointer',
  width: '100%',
  // height: '100%',
  outline: 'none',
});
const BannerCloseButton = ({ onClick }) => (
  <Button onClick={onClick}>
    <span style={{ display: 'flex', justifyContent: 'center' }}>
      <span style={{ paddingLeft: '5px', paddingTop: '1px' }}>CLOSE</span>
    </span>
  </Button>
);
export default BannerCloseButton;
