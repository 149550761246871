import React from 'react'
import useTimeDifference from 'utils/useTimeDifference'
import BannerText from './components/BannerText'
import BannerCloseButton from './components/BannerCloseButton'
import BannerCountdown from './components/BannerCountdown'

import {
  PopupGridStyled,
  BannerCloseWrapperStyled,
  BannerContainerStyled,
} from './styles'

/**
 *
 * @param {string} headlineHtml
 * @param {string} fullUri
 * @param {function} onHide
 * @param {boolean} visible
 * @param {number} offerPopupExpirationDate
 * @param {string} offerPopupType
 * @return {*}
 * @constructor
 */
export default function PageBanner({
  headlineHtml,
  fullUri,
  offerPopupExpirationDate,
  offerPopupType,
  offerPopupLayout,
  offerPopupStretch,
  offerPopupPosition,
  onHide,
  visible,
  pageContext,
}) {
  // const offerPopupExpirationDate = new Date() * .001 + (1 * 5); // Test Time 5 Sek
  const time = useTimeDifference(offerPopupExpirationDate)
  const hasTimeDiff = time > 0
  const showBanner =
    visible && (hasTimeDiff || offerPopupExpirationDate === null)

  // useEffect(() => {

  // }, [visible]); // Only re-run the effect if count changes

  const showSeparateCountdown = offerPopupType === 'offerPopupType2'
  const showTextCountdown =
    offerPopupType === 'offerPopupType1'
      ? offerPopupExpirationDate === null
        ? false
        : true
      : false

  const layout = offerPopupLayout // white or green
  const stretch = offerPopupStretch
  const position = offerPopupPosition // bottom or top

  return (
    <BannerContainerStyled
      position={position}
      layout={layout}
      show={showBanner}>
      <PopupGridStyled layout={layout}>
        {showSeparateCountdown && (
          <BannerCountdown
            countdownDateTime={offerPopupExpirationDate}
            layout={layout}
          />
        )}
        <BannerText
          stretch={stretch}
          layout={layout}
          position={position}
          htmlText={headlineHtml}
          showTextCountdown={showTextCountdown}
          countdownDateTime={offerPopupExpirationDate}
          fullUri={fullUri}
          pageContext={pageContext}
        />
        <BannerCloseWrapperStyled>
          <BannerCloseButton onClick={() => (onHide ? onHide() : null)} />
        </BannerCloseWrapperStyled>
      </PopupGridStyled>
    </BannerContainerStyled>
  )
}
