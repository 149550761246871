import React, { useState, useEffect } from "react";
import PageBanner from "components/PageBanner";
import GlobalBanner from "components/GlobalBanner";
import isClient from "utils/isClient";
import { graphql, useStaticQuery } from "gatsby";
import useLocalStorage from "utils/hooks/useLocalStorage";
import md5 from "tiny-hashes/md5";

export default function Banner({
  offerPopupHeadline,
  offerPopupExpirationDate,
  fullUri,
  offerPopupType,
  offerPopupLayout,
  offerPopupStretch,
  offerPopupPosition,
  curPageTitle,
  pageContext,
}) {
  let globalBanner = null;
  if (pageContext) {
    const staticData = useStaticQuery(graphql`
      query BannerGlobalsStatic {
        craft {
          # Globals
          globalsDe: globals(siteId: 1) {
            ...GlobalBannerQuery
          }

          globalsEn: globals(siteId: 2) {
            ...GlobalBannerQuery
          }
        }
      }
    `);

    const { globalsDe, globalsEn } = staticData?.craft;

    const globals = pageContext.siteId === 1 ? globalsDe : globalsEn;
    globalBanner = { ...globals.banner };
  }
  const date = new Date();
  const dateString =
    date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();

  const bannerTextHash = md5(globalBanner?.offerPopupHeadline?.content || "");
  const [pageBannerStorageHidden, setPageBannerStorageHidden] = useLocalStorage(
    `pageBannerHidden-${bannerTextHash}-${dateString}`,
    false
  );
  const [
    globalBannerStorageHidden,
    setGlobalBannerStorageHidden,
  ] = useLocalStorage(
    `globalBannerHidden-${bannerTextHash}-${dateString}`,
    false
  );

  const [pageBannerHidden, hidePageBanner] = useState(true);
  const [globalBannerHidden, hideGlobalBanner] = useState(true);

  useEffect(() => {
    hideGlobalBanner(globalBannerStorageHidden === true);
  }, [globalBannerStorageHidden]);

  useEffect(() => {
    hidePageBanner(pageBannerStorageHidden === true);
  }, [pageBannerStorageHidden]);

  const handlePageBannerHide = () => {
    setPageBannerStorageHidden(true);
    hidePageBanner(true);
  };
  const handleGlobalBannerHide = () => {
    setGlobalBannerStorageHidden(true);
    hideGlobalBanner(true);
  };

  return (
    <div>
      {/* if offerPopupHeadline field is set on current page show page banner */}
      {offerPopupHeadline ? (
        <PageBanner
          visible={!pageBannerHidden}
          onHide={() => handlePageBannerHide()}
          fullUri={fullUri}
          offerPopupType={offerPopupType}
          offerPopupLayout={offerPopupLayout}
          offerPopupStretch={offerPopupStretch}
          offerPopupPosition={offerPopupPosition}
          headlineHtml={offerPopupHeadline?.content || ""}
          offerPopupExpirationDate={offerPopupExpirationDate}
          pageContext={pageContext}
        />
      ) : globalBanner && globalBanner?.offerPopupHeadline ? (
        <GlobalBanner
          visible={!globalBannerHidden}
          onHide={() => handleGlobalBannerHide()}
          currentPage={curPageTitle}
          pageContext={pageContext}
          {...globalBanner}
        />
      ) : null}
    </div>
  );
}
